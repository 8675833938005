import Location1 from '../assets/images/Location1.png';
import Group41 from '../assets/images/Group41.png';
import Group42 from '../assets/images/Group42.png';
import Group43 from '../assets/images/Group43.png';
import LargeTitle from '../assets/images/LargeTitle.png';
import CreateReport from '../assets/images/createReport.png';
import BottomButtonsIcon from '../assets/images/BottomButtonsIcon.png';
import Rectangle2701 from '../assets/images/Rectangle2701.png';
import Group7987 from '../assets/images/Group7987.svg';
import Group7927 from '../assets/images/Group7927.png';
import MainDasboard3 from '../assets/images/MainDasboard3.png';
import IPhone12_1 from '../assets/images/iPhone12_1.png';
import IPhone12_2 from '../assets/images/iPhone12_2.png';
import IPhone12_3 from '../assets/images/iPhone12_3.png';
import IPhone12_4 from '../assets/images/iPhone12_4.png';
import IPhone12_5 from '../assets/images/iPhone12_5.png';
import Start22Screen1 from '../assets/images/Start22Screen1.png';
import Start22Screen2 from '../assets/images/Start22Screen2.png';
import Group7955 from '../assets/images/Group7955.png';
import Group7957 from '../assets/images/Group7957.png';

export const en = {
  title: 'English',
  shortTitle: 'EN',
  translation: {
    Header: {
      Home: 'Home',
      Products: 'Products',
      'Citiapp Mobile': 'Citiapp Mobile',
      'Where citizens': 'Where citizens control, add,<br />and get closer.',
      'Citiapp Dashboard': 'Citiapp Dashboard',
      'Where owner':
        'Where owner can get insights,<br />create events and send news.',
      About: 'About',
      'Log in': 'Log in',
      'Request Demo': 'Request A Demo',
      'Main menu': 'Main menu',
      FAQ: 'FAQ',
    },
    YellowSection: {
      h1: 'The only solution your city will ever need',
      'All-in-one':
        'All-in-one citizen engagement solution for a smart and robust city.',
    },
    ModTechSection: {
      'Modern technologies':
        "Modern technologies and AI make citizens' lives easier",
      tab0: {
        tabHeader: 'Find issues',
        tabHeader2: 'Anywhere in your city',
        header1: `Raising issues has`,
        header2: `never been easier`,
        checkbox0: `See all the created reports in your location, comment, and update them quickly.`,
        checkbox1: `Create new reports such as errors, suggestions, complaints, or raise a question.`,
        Location1: Location1,
        Group41: Group41,
        Group42: Group42,
        Group43: Group43,
        LargeTitle: LargeTitle,
      },
      tab1: {
        tabHeader: 'Report them',
        tabHeader2: 'With only few clicks',
        header1: `Send reports to`,
        header2: `people who care`,
        checkbox0: `Don’t want to write a text? A picture is worth a thousand words.`,
        checkbox1: `Citizens choose whether to track the progress of the reports they created.`,
        CreateReport: CreateReport,
        BottomButtonsIcon: BottomButtonsIcon,
      },
      tab2: {
        tabHeader: 'Receive report',
        tabHeader2: 'Filtered automatically',
        header1: `Many things can be`,
        header2: `automated`,
        checkbox0: `AI tool to identify what issue type was reported, prioritize, and put them first in line.`,
        checkbox1: `You choose if you want some types of reports to be forwarded to service providers automatically.`,
        Rectangle2701: Rectangle2701,
        Group7987: Group7987,
      },
      tab3: {
        tabHeader: 'Get solved',
        tabHeader2: 'Track progress',
        header1: `Contractors receive`,
        header2: `damage reports`,
        checkbox0: `You can add new internal reporters and new contractors who handle the damages.`,
        checkbox1: `Overview statistics about all received reports and export them when needed.`,
        Group7927: Group7927,
        MainDasboard3: MainDasboard3,
      },
    },
    MoreThanAppSection: {
      h2: {
        part1: 'It is ',
        part2: 'more than',
        part3: ' an app',
      },
      h3: {
        part1: 'This is ',
        part2: 'NOT ',
        part3: 'everything.',
      },
      'We have': 'We have too much more to talk about.',
      'Want to': 'Want to know more?',
      'Ask Us': 'Ask Us',
      el0: {
        title: 'Communicate',
        text: 'A safe and secure communication with citizens. Roll out updates swiftly and spread information quickly.',
      },
      el1: {
        title: 'Report',
        text: 'Reporting problems in public places has never been easier. Receive instant updates about public issues or damages.',
      },
      el2: {
        title: 'Follow up',
        text: 'My pages enables citizens to track their issues easily throughout the process, and respond promptly.',
      },
      el3: {
        title: 'Decide',
        text: 'Fast, accurate information makes a meaningful difference in making the right decision.',
      },
      el4: {
        title: 'Enjoy',
        text: 'Enjoying local attractions is delightful. Enable citizens to have big time with family and friends around the city.',
      },
      el5: {
        title: 'Contribute',
        text: 'Making citizens part of the changes is paramount to success. Enable citizens to drive change.',
      },
      el6: {
        title: 'Attend',
        text: 'Entertaining events spread joy. Show citizens what they are missing.',
      },
      el7: {
        title: 'Alert',
        text: 'Be situationally aware. Get safety alerts when they matter to you in real-time.',
      },
      el8: {
        title: 'Be Ready',
        text: 'Always get crisis information at the right time and be ready for the next step.',
      },
    },
    SubscribeSection: {
      header: 'Subscribe to the newsletter',
      p: 'Get to know our latest updates, releases, and news about Citiapp.',
      Subscribe: 'Subscribe',
    },
    MobileAppSection: {
      greenMarksArr: {
        mark1: 'Learn about your city',
        mark2: 'Guest Login',
        mark3: 'Easy Share',
      },
      threeIPhonesArr: {
        iPhone1: {
          img: IPhone12_1,
          text: 'Citizens get real-time notifications about activities and community events that interests them.',
        },
        iPhone2: {
          img: IPhone12_2,
          text: 'Citizens can see all the entertainment places in their city presented beautifully at a glance.',
        },
        iPhone3: {
          img: IPhone12_3,
          text: 'During emergencies citizens receive timely location-based alerts to save their time and their lives.',
        },
      },
      playYourPartArr: {
        item1: {
          title: 'Report problems',
          text: 'If issues in public places are annoying citizens, they can report them instantly.',
        },
        item2: {
          title: 'Suggest ideas',
          text: 'Being part of positive changes in the city must feel good. Have ideas to suggest?',
        },
        item3: {
          title: 'Raise complaints',
          text: 'Don’t have time to call and wait on the line to report a complaint? Do it here. It takes a minute.',
        },
        item4: {
          title: 'Ask questions',
          text: 'Citizens have questions? They can ask them here and get answers as soon as possible.',
        },
        IPhone12_4: IPhone12_4,
        IPhone12_5: IPhone12_5,
      },
      'Everyday Life': 'Your Everyday Life Is much Easier with Citiapp',
      h1: 'Where you feel in control',
      'Play part': 'Play your part in the society',
      Calendar: 'Calendar',
      'With Calendar':
        'With Calendar citizens can see all events happing in the city. Not only that, they can save their favorite events and get notifications nearer the event’s time.',
      Group7955: Group7955,
      Group7957: Group7957,
      Start22Screen1: Start22Screen1,
      Start22Screen2: Start22Screen2,
      'My Pages': 'My Pages',
      'direct communication':
        'My pages is the direct communication tool for the citizens when it comes to tracking their matters and cases. They get updated about the status, arrangements, and completion of their open issues.',
    },
    DashboardSection: {
      'right information':
        'Where the right Information comes in the right time',
      h1: 'Everything is taken care of',
      greenMarksArr: {
        mark1: 'Turn data to insights',
        mark2: 'Manage your network',
        mark3: '24/7 support',
      },
      fourNotes: {
        note1:
          'Get a quick overview about report types, suggestions, complaints and questions with only one click.',
        note2:
          'Add, edit and remove news, alerts and event posts and send them to all citizens quickly and easily.',
        note3:
          'Automate some of your work steps so you can get reports assigned efficiently and resolved quickly.',
        note4:
          "Utilise citizens' participation and engagement in developing and strengthen your city.",
      },
      'Get insights': 'Get valuable insights about citizens’ interests',
      checkboxes: {
        check1: `Receive new reports and forward them to service providers or automate it.`,
        check2: `We can talk more about functionalities when we meet with you.`,
      },
      'Filter reports': 'Filter reports based on your needs and priorities',
      'Personalise dashboard':
        'Personalise your dashboard with high flexibility to serve your preferences.',
      Reporters: 'Reporters',
      'Any citizen':
        'Any citizen in your city is a reporter, but you can also add specific or internal reporters, such as governmental offices, schools, and so on. Let us talk more when we meet with you.',
      Suppliers: 'Contractors',
      'Service providers':
        'Contractors can also use your dashboard to update the status of reports and highlight the progress. Reports are sent to service providers by email to assure delivery.',
    },
    AboutSection: {
      'Making citizens': 'Making citizens everyday life easier!',
      'In our':
        "In our world, information is becoming more accessible, and sharing has become more effortless. Still, we have yet to see technology's potential to build smarter cities and make life easier for citizens. When others talk about a smart city, we talk about a happy citizen.",
      'Olivux is':
        "Olivux is a specialised IT company in Sundsvall, Sweden, focusing on sustainable and secure city solutions that help build smart cities. Thanks to AI, IoT, and Cloud Computing technologies, we can automatically learn from users' preferences, needs, and interests and create solutions with outstanding performance and high quality. Citiapp is our first application aimed at making citizens happier in their city and makes the municipal and governmental services more accessible, manageable and informative.",
      'We Are': 'We Are Olivux',
      'My name':
        'My name is Samir Shnino, a developer and a software engineer with extensive experience developing public and governmental solutions and IT systems that are innovative, intuitive and  user-centric. The idea of Citiapp sparked in my mind after various encounters with people around me trying to control their everyday life details, access information instantly and reduce the hustling noise of using several different services that have no connection whatsoever. I realised that I can do better and provide a solution that eliminates the root cause of the problem. So I decided to quit my job as an employee and establish my own company to pursue my passion and translate my ideas to a usable and efficient solution, Citiapp!',
      'Given my':
        'Given my experience working in the public and governmental IT sector, along with my team’s impressive talents and qualifications, we developed a clear vision of what we want to do, we have self-confidence, and we know what it takes to build a system with the right technologies that makes citizens happier. Our expertise in user experience (UX) design makes us aware of how important usability and value in our app should be. We dedicate time to designing and testing our applications, so they exceed the expectations of our users.',
      'Our values': 'Our values',
      checkboxesArr: {
        check1: {
          title: 'Culture of simplicity',
          text: 'We believe deeply, think intelligibly, and design lucidly to maximise the usability and value of our apps.',
        },
        check2: {
          title: 'Quality never compromised',
          text: 'Quality is paramount for us.',
        },
        check3: {
          title: 'Less is more',
          text: 'We aim to replace many of the users’ mobile apps with a few simple and valuable solutions.',
        },
        check4: {
          title: 'Transparency and trustworthiness',
          text: 'We are transparent with our customers and our team.',
        },
      },
      'Our mission': 'Our mission',
      'We are':
        'We are on a mission to building new cutting-edge solutions that will make life easier, the community more exciting, and information more personalised, on-time, and accessible for citizens. We are building Citiapp, the “Citizen Super App”.',
      'Our vision': 'Our vision',
      'Setting the':
        'Setting the benchmark of utilising the full potential of current technology for a premium citizens’ life.',
      'Leadership team': 'Leadership team',
      'Founder CEO':
        'Founder, CEO and solution architect at Olivux. 15 years of experience in designing and developing IT-systems in the public sector in Sweden. Full-time smart cities enthusiast.',
      'CIO and innovation manager':
        'CIO and innovation manager at Olivux. 15 years of experience in the development of the public sector. Certified change manager and CIO.',
      'Senior Advisor':
        'Senior Advisor, 25 years of experience as managing director and member of executive management boards, mainly in the international IT Business in Sweden.',
      'Sales Manager':
        'Sales Manager and Business Developer with over 30 years in IT service delivery, including roles as CEO and Sales Lead, on both national and international levels.',
    },
    RequestSection: {
      'Send your':
        'Send your request now and be among the first organizations to join our community. One of our sales team members will contact you shortly.',
      'Full Name': 'Full Name',
      'Work Email': 'Work Email',
      'Phone Number': 'Phone Number',
      City: 'City',
      'Your Message': 'Your Message',
      'Submit Request': 'Submit Request',
      'By Submitting': {
        text: 'By Submitting you are accepting the ',
        link: 'terms of services and privacy policy.',
      },
    },
    FaqSection: {
      h4: 'FAQ',
      faq1: {
        title: 'Privacy and Permissions',
        text: 'Get to know the privacy and permissions that are associated with Citiapp and all its applications.',
        info1: {
          title: 'Does Citiapp save any personal data?',
          text: 'Personal data are handled following best practices and provide safeguards to protect data to ensure complete anonymity. We save some of your data like name, phone number, and email, but we make sure that this data is protected and no third-part network will ever access it. In other words, we follow the GDPR.',
        },
        info2: {
          title:
            'Does Citiapp track and share device location data or email with a third-party advertising network?',
          text: 'The citizens will be asked by Citiapp whether to allow permission to track their location. The app tracks citizen’s location to provide best information in real-time. No third-party network will access this tracking information, and it will be used only by Citiapp. The citizen can always turn off the tracking location from the app settings.',
        },
        info3: {
          title: 'What methods are used to login in Citiapp?',
          text: 'For citizens the two primary methods are BankID or as Guest login but there are two other optional methods Apple Login and Google Login if the municipality chose to include them in the app. Municipal users can log in to the Citiapp Dashboard with their Citiapp credentials but we are planning to implement SAML 2.0.',
        },
      },
      faq2: {
        title: 'Pricing and Plans',
        text: 'Learn more about our pricing scheme for Citiapp and its features.',
        info1: {
          title: 'How much do we pay as a municipality / city?',
          text: 'It all depends on what features your municipality chose to include in your app. Remember that many features and functions are optional. You can always choose to add or remove them. So you only pay for the features you use. And the potential number of users who are using the app also plays a role in the price you pay.',
        },
        info2: {
          title: 'Are the maintenance and support included in the price?',
          text: 'We believe that maintenance and support are parts of any product. Therefore the maintenance and support are always included in the price for our Apps.',
        },
      },
      faq3: {
        title: 'Citiapp and Features',
        text: 'Have a close look at how Citiapp works, organize, and support the city you are in.',
        info1: {
          title: 'What is Citiapp?',
          text: 'Citiapp “Citizen Super Application” is the name of the ecosystem that includes few mobile and web apps. Citiapp is a mobile application that will be used by citizens. Other Citiapp websites will be used by the municipality and other service providers to provide the citizens with information.',
        },
        info2: {
          title: 'How many features or functions does Citiapp has?',
          text: 'Citiapp is an application with numerous features and functions. Those features and functions are grouped into six categories to make it easier for citizens to use Citiapp and simplify the usability experience.',
        },
        info3: {
          title:
            'Do the customers have to have all these features / functions?',
          text: 'While there are essential functions for all municipalities by default, customers can choose from lots of other optional parts to include in their Citiapp.',
        },
        info4: {
          title:
            'Can a customer add a new customised function that is not included in the function categories?',
          text: 'Olivux is a citizen-centric company that strives to satisfy customers and meet their requirements. Hence customized functions can be added at an extra cost based on scope and complexity.',
        },
      },
    },
    Privacy: {
      h1: 'Olivux Privacy Policy',
      updated: 'Last updated: January 2022',
      Welcome: {
        h: 'Welcome to Olivux!',
        text0:
          'At Olivux we pride ourselves to be the first company to appreciate the multidimensionality of the meaning of smart cities. We replace the silo effect of multiple fragmented applications by synthesising data, technology and community to build smarter cities and make life easier for citizens. As we believe there is a high correlation between happy citizens and smart cities, our products and services are designed adopting a human-centred approach to empower citizens to experience smart cities on a whole new level. All our products and services are designed to embrace a unique user experience based on understanding, integration, value and functionality. To be able to deliver our promised level of service, we rely on data obtained from our users, thus we collect, store and use such data.',
        text1:
          "At Olivux we responsibly handle the collection, storage, usage and protection of our user's personal information to ensure respecting privacy. In addition, we comply with the General Data Protection Regulation (GDPR) and its principles.",
        text2:
          "The following Privacy Policy describes what data and/or information we collect and demonstrates why we require it. The policy also shows our invested efforts to responsibly manage, store, use and protect users' data and information.",
        text3:
          "Data and information might be used interchangeably in this policy, and they both refer to any collectables that Olivux requests or automatically records and processes from its users' base.",
      },
      Collect: {
        h: 'What personal data do we collect?',
        text1:
          "We record different types of data and/or personal information. Some data are collected after prompting users' consent, and other data are automatically recorded. The data and/or information that we collect may include (but are not limited to):",
        li1: 'Full Name',
        li2: 'Gender',
        li3: 'Usernames and passwords',
        li4: 'Email address',
        li5: 'Mobile number',
        li6: 'Location (can be turned off) → indicate whenever it can be turned off by users',
        li7: 'Activity history',
        li8: 'Content (messages, pictures, videos, etc.) sent via some services (i.e., reporting problems in public places)',
        li9: 'Data about family members, their age and gender.',
        text2:
          "In our applications, users have the liberty to register or create their accounts in two options. The first option is using an email address (only Gmail or Apple email), while the second option is through the user's Bank Id. Both options have to be coupled with a password of users creation following certain security rules.",
        text3:
          "The first option can be used by both local citizens and temporary residents (as guests), where users basically enjoy all the features available in the application except for those related to municipal services (e.g., 'My pages' in Citiapp). It is worthy of mentioning that users registering with their Apple ID enjoy the safety and security offered by Apple without disclosing their actual email addresses.",
        text4:
          'The second option requiring users to register through their Bank ID offers full access to all features since the Bank ID allows users to benefit from all municipal services.',
        text5:
          "In all cases, we at Olivux ensure that users' data and personal profiles are not compromised and will be protected all the time.",
      },
      Keep: {
        h: "Where do we keep users' information and data?",
        text: 'Olivux is an information technology company registered and based in Sweden. To this end, we comply with the European GDPR principles. Therefore, collected data is stored in cloud servers in Sweden with high information security levels and access activities being logged and monitored.',
      },
      Need: {
        h: 'Why do we need data and/or personal information?',
        Accessibility: {
          h: 'Accessibility',
          text: 'We request personal contact information such as, for example, name, email address and mobile number to complete our registration, administration and operation processes to allow for subsequent processes such as logging in, restoring username and password, activating and deactivating accounts and accessing numerous services available in the applications we develop.',
        },
        Curated: {
          h: 'Curated Content',
          text1:
            "As for location data and tracking, we process such information to provide users with location-specific services, events and activities in addition to route optimisation to offer the utmost level of service they expect and personalise experiences to satisfy users' requirements and needs.",
          text2:
            'Regarding data about family members, we only require general information such as ages and gender and process them to refine children-related events, activities and recommendations.',
          text3:
            'Users can always opt out from sharing such data and information. However, all services that depend on such information will either be unavailable or will not show high-quality results.',
        },
        Personalisation: {
          h: 'Personalisation & Customisation',
          text1:
            'By collecting activities history, our engines recommend similar activities, experiences and suggestions to the users based on preferences inferred from tailoring historical behaviours and patterns. We also collect statistics to monitor the usage of our services, ensure no misuse, enhance our services and record user-specified settings (see cookies clause below) to ensure service consistency.',
          text2:
            'Users can always opt out from sharing such data and information. However, all services that depend on such information will either be unavailable or not function properly.',
        },
        Marketing: {
          h: 'Marketing',
          text1:
            "We do not use collected users' information and/or data for marketing purposes. Whenever we update this policy, we will ask permission from users and will only collect information and/or data if users voluntarily consent or provide permission to collect them for marketing purposes.",
          text2:
            "In an attempt to further protect our users' data and personal information, we do not share our collected data with any third-party service providers.",
        },
        Contact: {
          h: 'Contact and reporting',
          text1:
            'Whenever users report errors or file complaints related to our applications and/or solutions, we keep their contact information and additional relevant content such as reports, conversations, pictures and videos to respond to their requirements and complaints for additional internal-use development purposes.',
          text2:
            'Other situations where we keep track of reports, conversations, pictures and videos and all other attachments or metadata are in Citiapp when users report problems and mistakes found at public places. Keeping a record of such data must comply with any further legal or compliance requirements.',
        },
      },
      Retain: {
        h: "How do we retain users' personal information?",
        text1:
          "Users' personal information and data are retained in our databases unless users request them to be deleted. We ensure high levels of security protocols are imposed to avoid loss, illegal misuse or processing, modification or disposal of our users' data and personal information. However, we do not control security measures of third-party services such as email service providers, hence, when we receive emails from users, we are not responsible for any loss or leak that happens before receiving such information. Thus, users should be cautious about what data they share and with whom.",
        text2:
          'In some cases, we might keep such data and information for development and testing purposes but will ensure such data is anonymised and with no link to the users. Data can be copied or stored in several locations for protection and backup purposes.',
      },
      Disclose: {
        h: "When might we disclose users' personal information?",
        text: "There are some situations when we might disclose or share users' data. Hence, we list some cases when such disclosure might happen.",
        Business: {
          h: 'Business Change:',
          text: 'Whenever our business status changes by either acquiring other businesses, merging with other companies, internal restructuring or reorganisation, exit of business or any similar events, we might transfer, share or disclose data.',
        },
        Users: {
          h: "Users' Consent:",
          text: "A normal situation where we might share users' data with external parties is whenever we have users' consent to do so.",
        },
        Legal: {
          h: 'Legal Requirements:',
          text: 'It is a mandatory requirement to abide by law for our companies. Given that legal and judicial rules and regulations are still lagging in the IT sector, we might find ourselves in situations where we have to comply with law enforcement agencies and disclose some data and information to fulfill legal requirements, protect our business entity, protect our users or cooperate to avoid any legal liability.',
        },
      },
      Rights: {
        h: "Users' Rights and Responsibilities",
        text1:
          'As indicated many times earlier, users can opt out from sharing some information (information not vital to complete basic operations) while using our solutions and applications. They can also request their data and information be deleted if they decide to deactivate their accounts.',
        text2:
          'In case of any mistakes or errors in personal information, users have the right to request corrections or modifications. On the other hand, users are encouraged not to misuse our services, applications and or solutions in any known or unknown way. Users are also responsible for keeping their devices and not sharing log-in information with other people to avoid any leak, deletion, alteration and or misuse of their private data and information.',
      },
      AgeAppropriate: {
        h: 'Age-Appropriate Usage',
        text: 'At Olivux, all our solutions and/or applications are intended for users aged 18 years and over. We do not intentionally collect data from users under 18 years of age. Thus, should it come to our knowledge that such an incident has happened, we will take all reasonable measures to remove such data from our records. We would also urge all parents and/or guardians who are aware that their children under 18 years of age are using our applications, they should contact us to notify us or provide their consent allowing us to store and process their data.',
      },
      Cookies: {
        h: 'Cookies Policy',
        text1:
          'When users start using our solutions/applications/products, they will be automatically notified that we use cookies to enhance our services, and hence users are prompted to provide their consent. This is done in various ways, such as pop-ups or notification banners. Consenting to the use of cookies is optional. Users can reject the request, and we will respect their wishes. Upon users’ consent, we transfer unique identifiers (collectively referred to as cookies, hereafter) to users’ devices to recognise them upon login, enable personalised recommendations, allow for preferences to be stored to customise the services and features offered in our applications and solutions. Using cookies allows us to:',
        li1: 'Keep records of users’ login information (username, password, last login timestamp, etc.)',
        li2: 'Keep records of users’ likes and dislikes',
        li3: 'Keep records of users’ choice of alarms, news and notifications types',
        li4: 'Track the performance of our services to improve service levels',
        text2:
          'We value the privacy of our users. Therefore, we DO NOT use third-party cookies, which are cookies used to share information with third parties such as advertisers or social media platforms. Users’ can opt-out/disable/block cookies sent to their devices. They can go to the ‘Settings’ section and disable or reject the cookies option.',
      },
      Updates: {
        h: 'Privacy Policy Updates',
        text: 'We regularly review our privacy policy terms to abide by the rules and regulations. Should our privacy policy change in any form as a result of our regular revisions, either by adding new terms or modifying some, we will immediately inform our users of any updates by circulating the update via email and by prompting users to review such updates and provide their consent inside our applications and/or solutions.',
      },
      Contacting: {
        h: 'Contacting Us',
        text: 'Feel free to contact us if you have any questions about our Privacy Policy or the information Practices of the Services. You may contact us on ',
        link: 'privacy@olivux.se',
      },
    },
  },
};
