import React, { useState } from 'react';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Rectangle2297 from '../assets/images/Rectangle2297.png';
import Rectangle2100 from '../assets/images/Rectangle2100.png';
import Dots from './Dots';
import './SubscribeSection.scss';

const postUrl = 'https://citiapp.us5.list-manage.com/subscribe/post';
const u = '323742c0c07543e42c91a26b1';
const id = '060a2756b3';

export default function SubscribeSection() {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const handleSubmit = ({ e, subscribe, email }) => {
    e.preventDefault();
    email.indexOf('@') > -1 &&
      subscribe({
        MERGE0: email,
      });
  };

  return (
    <div className='subscribe-section'>
      <Container>
        <Row>
          <Col className='position-relative' xs={12} lg={6}>
            <div className='h-100 w-100 left-side'>
              <h4>{t('SubscribeSection.header')}</h4>
              <p className='gilroy-reg-24'>{t('SubscribeSection.p')}</p>
              <MailchimpSubscribe
                url={`${postUrl}?u=${u}&id=${id}`}
                render={({ subscribe, status, message }) => (
                  <React.Fragment>
                    {status === 'sending' && (
                      <p className='gilroy-semibold-17'>Processing...</p>
                    )}
                    {status === 'success' && (
                      <p className='gilroy-semibold-17 text-success'>
                        Congratulations! You are successfully subscribed.
                      </p>
                    )}
                    {status === 'error' && (
                      <p className='gilroy-semibold-17 text-danger'>
                        {`Sorry, the error has occurred: ${
                          message.split(' <a')[0]
                        }`}
                      </p>
                    )}
                    {!status && (
                      <p>
                        <br />
                      </p>
                    )}

                    <Form
                      onSubmit={(e) => handleSubmit({ e, subscribe, email })}
                    >
                      <Form.Control
                        type='email'
                        name='email'
                        placeholder='john@john.com'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                      <Button type='submit'>
                        {t('SubscribeSection.Subscribe')}
                      </Button>
                    </Form>
                  </React.Fragment>
                )}
              />
            </div>
            <Dots x={6} y={5} />
            <Image src={Rectangle2100} className='rectangle2100' />
          </Col>
          <Col xs={0} lg={6} className='d-flex align-items-center'>
            <Image
              src={Rectangle2297}
              className='right-img d-none d-lg-block'
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
