import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import appRoutes from './routes/appRoutes';
import { buildRoutes } from './utils/routeUtils';
import './App.scss';

function App() {
  return (
    <Router>
      <Switch>
        {buildRoutes(appRoutes).map((route) => {
          const routeProps = {
            path: route.path,
            exact: route.exact,
            key: route.path,
            component: route.component,
          };

          return <Route {...routeProps} />;
        })}
      </Switch>
    </Router>
  );
}

export default App;
