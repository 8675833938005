import { Image } from 'react-bootstrap';
import Vector from '../assets/images/Vector.svg';

export default function GreenMark({ title }) {
  return (
    <div className='green-mark d-flex align-items-center justify-content-center'>
      <Image src={Vector} />
      <p className='mb-0 ms-2 footer-font'>{title}</p>
    </div>
  );
}
