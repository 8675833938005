import { useState } from 'react';
import { Accordion, Card, Image } from 'react-bootstrap';
import ChevronDown from '../assets/images/Arrow-Up2.svg';
import './FaqInfoBlock.scss';

export default function FaqInfoBlock({ infoArr, className }) {
  const [activeCard, setActiveCard] = useState(0);
  const toggleClickHanlder = (i) => {
    if (activeCard === i) {
      setActiveCard(null);
    } else setActiveCard(i);
  };

  return (
    <div className={'faq-info-block ' + className ?? ''}>
      <Accordion defaultActiveKey='0'>
        {infoArr.map((info, i) => {
          return (
            <Card className={activeCard === i ? 'active' : ''} key={i}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={'' + i}
                onClick={() => toggleClickHanlder(i)}
              >
                <h6>
                  {info.title}
                  <Image src={ChevronDown} className='chevron-down' />
                </h6>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={'' + i}>
                <Card.Body>
                  <p className='gilroy-reg-24'>{info.text}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </div>
  );
}
