import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutSection from '../components/AboutSection';

export default function About() {
  return (
    <React.Fragment>
      <Header current={3} />
      <AboutSection />
      <Footer />
    </React.Fragment>
  );
}
