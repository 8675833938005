import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Dots from './Dots';
import './ModTechImgRecieveReport.scss';

export default function ModTechImgRecieveReport() {
  const { t } = useTranslation();

  return (
    <div className='mod-tech-img-recieve-report'>
      <Image
        src={t('ModTechSection.tab2.Rectangle2701')}
        className='rectangle2701'
      />
      <Dots x={2} y={2} className='dots22' />
      <Dots x={3} y={2} className='dots32' />
      <Image src={t('ModTechSection.tab2.Group7987')} className='group7987' />
    </div>
  );
}
