import React, { useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import BackLink from './BackLink';

const postUrl = 'https://yandex.us5.list-manage.com/subscribe/post';
const u = 'ee438ed7f5790e5eca188484a';
const id = 'dd88d38c9e';

export default function SubscribeInner() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleSubmit = ({ e, subscribe, email, firstName, lastName }) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf('@') > -1 &&
      subscribe({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };

  return (
    <Container className='subscribe-inner'>
      <Row>
        <Col>
          <BackLink title='Home' link='/' />
          <h2>Subscribe</h2>
          <MailchimpSubscribe
            url={`${postUrl}?u=${u}&id=${id}`}
            render={({ subscribe, status, message }) => (
              <React.Fragment>
                {status === 'sending' && (
                  <p className='gilroy-semibold-17'>Processing...</p>
                )}
                {status === 'success' && (
                  <p className='gilroy-semibold-17 text-success'>
                    Congratulations! You are successfully subscribed.
                  </p>
                )}
                {status === 'error' && (
                  <p className='gilroy-semibold-17 text-danger'>
                    {`Sorry, the error has occurred: ${
                      message.split(' <a')[0]
                    }`}
                  </p>
                )}
                <Form
                  onSubmit={(e) =>
                    handleSubmit({ e, subscribe, email, firstName, lastName })
                  }
                >
                  <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      name='firstName'
                      type='text'
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      name='lastName'
                      type='text'
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder='e.g john@john.com'
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </Form.Group>
                  <Button type='submit'>Subscribe</Button>
                </Form>
              </React.Fragment>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}
