import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import BackLink from './BackLink';
import './ResetInner.scss';

export default function ResetInner() {
  return (
    <Container className='reset-inner'>
      <Row>
        <Col>
          <BackLink title='Log in' link='/login' />
          <h2>Reset Password</h2>
          <p className='small-poppins'>
            Use the email you have used to create an account for Citiapp, will
            send you a link to help you recover.
          </p>
          <Form>
            <Form.Group>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type='email' placeholder='e.g john@john.com' />
            </Form.Group>
            <Button type='submit'>Send link to Email</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
