import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ThreeGreenLines from './ThreeGreenLines';
import './ModTechImgReportThem.scss';

export default function ModTechImgReportThem() {
  const { t } = useTranslation();

  return (
    <div className='mod-tech-img-report-them'>
      <Image
        src={t('ModTechSection.tab1.CreateReport')}
        className='create-report'
      />
      <ThreeGreenLines />
      <Image
        src={t('ModTechSection.tab1.BottomButtonsIcon')}
        className='bottom-buttons-icon'
      />
    </div>
  );
}
