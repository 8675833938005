import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeviceMashup from '../assets/images/3DeviceMashup.png';
import Rectangle from '../assets/images/Rectangle2100.png';
import Dots from './Dots';
import './YellowSection.scss';

export default function YellowSection() {
  const { t } = useTranslation();

  return (
    <div className='yellow-section'>
      <Container>
        <Row>
          <Col xs={12} lg={5} className='left-col text-center text-lg-start'>
            <h1>{t('YellowSection.h1')}</h1>
            <p className='small-poppins'>{t('YellowSection.All-in-one')}</p>
            <Button as={Link} to='/request' className='d-inline-flex'>
              {t('Header.Request Demo')}
            </Button>
          </Col>
          <Col
            xs={12}
            lg={7}
            className='right-col d-flex align-items-center position-relative'
          >
            <Dots x={5} y={5} />
            <Image src={Rectangle} className='rectangle-img' />
            <Image src={DeviceMashup} className='device-mashup-img' />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
