import Location1Se from '../assets/images/Location1_se.png';
import Group41Se from '../assets/images/Group41_se.png';
import Group42Se from '../assets/images/Group42_se.png';
import Group43Se from '../assets/images/Group43_se.png';
import LargeTitleSe from '../assets/images/LargeTitle_se.png';
import CreateReportSe from '../assets/images/createReport_se.png';
import BottomButtonsIconSe from '../assets/images/BottomButtonsIcon_se.png';
import Rectangle2701Se from '../assets/images/Rectangle2701_se.png';
import Group7987Se from '../assets/images/Group7987_se.svg';
import Group7927Se from '../assets/images/Group7927_se.png';
import MainDasboard3Se from '../assets/images/MainDasboard3_se.png';
import IPhone12_1Se from '../assets/images/iPhone12_1_se.png';
import IPhone12_2Se from '../assets/images/iPhone12_2_se.png';
import IPhone12_3Se from '../assets/images/iPhone12_3_se.png';
import IPhone12_4Se from '../assets/images/iPhone12_4_se.png';
import IPhone12_5Se from '../assets/images/iPhone12_5_se.png';
import Start22Screen1Se from '../assets/images/Start22Screen1_se.png';
import Start22Screen2Se from '../assets/images/Start22Screen2_se.png';
import Group7955Se from '../assets/images/Group7955_se.png';
import Group7957Se from '../assets/images/Group7957_se.png';

export const sv = {
  title: 'Svenska',
  shortTitle: 'SV',
  translation: {
    Header: {
      Home: 'Hem',
      Products: 'Produkter',
      'Citiapp Mobile': 'Citiapp Mobile',
      'Where citizens': 'Where citizens control, add,<br />and get closer.',
      'Citiapp Dashboard': 'Citiapp Dashboard',
      'Where owner':
        'Where owner can get insights,<br />create events and send news.',
      About: 'Om',
      'Log in': 'Logga in',
      'Request Demo': 'Be om en demo',
      'Main menu': 'Meny',
      FAQ: 'Frågor och svar',
    },
    YellowSection: {
      h1: 'Den enda lösningen din stad behöver',
      'All-in-one': 'Allt-i-ett lösning för en smart och hållbar stad.',
    },
    ModTechSection: {
      'Modern technologies':
        'Moderna teknologier och AI kan göra invånarnas liv lättare',
      tab0: {
        tabHeader: 'Uppmärksamma fel',
        tabHeader2: 'Var som helst i din stad',
        header1: `Att uppmärksamma fel`,
        header2: `har aldrig varit enklare`,
        checkbox0: `Se alla skapade rapporter i ditt område, kommentera och uppdatera dem snabbt.`,
        checkbox1: `Skapa nya rapporter som fel, förslag, klagomål, eller uppmärksamma en fråga.`,
        Location1: Location1Se,
        Group41: Group41Se,
        Group42: Group42Se,
        Group43: Group43Se,
        LargeTitle: LargeTitleSe,
      },
      tab1: {
        tabHeader: 'Rapportera',
        tabHeader2: 'Med bara några klick',
        header1: `Skicka rapporter till`,
        header2: `människor som bryr sig`,
        checkbox0: `Vill du inte skriva en text? En bild säger mer än tusen ord.`,
        checkbox1: `Invånarna väljer om de vill följa utveckFgen av rapporterna som de har skapat.`,
        CreateReport: CreateReportSe,
        BottomButtonsIcon: BottomButtonsIconSe,
      },
      tab2: {
        tabHeader: 'Ta emot förfrågan',
        tabHeader2: 'Filtreras automatiskt',
        header1: `Många delar kan`,
        header2: `bli automatiserade`,
        checkbox0: `AI-verktyg för att identifiera vilken problemtyp som rapporterades, prioritera och sätta dem först i kön.`,
        checkbox1: `Du väljer om du vill att vissa typer av rapporter ska vidarebefordras till entreprenader automatiskt.`,
        Rectangle2701: Rectangle2701Se,
        Group7987: Group7987Se,
      },
      tab3: {
        tabHeader: 'Hanteras',
        tabHeader2: 'Följ dem hela vägen',
        header1: `Entreprenader tar`,
        header2: `emot skadeanmälningar`,
        checkbox0: `Du kan lägga till nya interna reportrar och nya entreprenörer som hanterar skadorna.`,
        checkbox1: `Översikt statistik om alla mottagna rapporter och exportera dem vid behov.`,
        Group7927: Group7927Se,
        MainDasboard3: MainDasboard3Se,
      },
    },
    MoreThanAppSection: {
      h2: {
        part1: 'Det ',
        part2: 'är mer än',
        part3: ' en app',
      },
      h3: {
        part1: 'Det ',
        part2: 'här är ',
        part3: 'inte allt.',
      },
      'We have': 'Vi har mycket mer att prata om.',
      'Want to': 'Vill du veta mer?',
      'Ask Us': 'Fråga oss',
      el0: {
        title: 'Kommunicera',
        text: 'En säker kommunikation med invånare. Genomför snabba uppdateringar och sprid information fort.',
      },
      el1: {
        title: 'Rapportera',
        text: 'Rapportera problem i allmänna platser har aldrig varit enklare. Få uppdateringar om skador.',
      },
      el2: {
        title: 'Uppföljning',
        text: 'Mina sidor hjälper invånare att följa deras ärenden enkelt hela vägen och reagera snabbt.',
      },
      el3: {
        title: 'Besluta',
        text: 'Snabb och rätt information skapar en meningsfull skillnad för att fatta det rätta beslutet.',
      },
      el4: {
        title: 'Ha kul',
        text: 'Invånare kan snabbt ta reda på vilka platser som finns i deras stad för att njuta av dem med familj och vänner.',
      },
      el5: {
        title: 'Bidra',
        text: 'Att göra invånare en del av förändring är avgörande för framgång. Tillåt invånare att göra deras röster hörda.',
      },
      el6: {
        title: 'Event',
        text: 'Roliga aktiviteter gör dina invånare glada. Presentera dem på ett bra sätt för dem.',
      },
      el7: {
        title: 'Notiser',
        text: 'Var medveten om situationer. Få säkerhetsnotiser när du behöver dem, i realtid.',
      },
      el8: {
        title: 'Var redo',
        text: 'Ta emot krisinformation i rätt tid och var förbered  inför nästa steg.',
      },
    },
    SubscribeSection: {
      header: 'Prenumerera på nyhetsbrevet',
      p: 'Håll koll på uppdateringar, lanseringar och nyheter om Citiapp.',
      Subscribe: 'Prenumerera',
    },
    MobileAppSection: {
      greenMarksArr: {
        mark1: 'Lär känna din stad',
        mark2: 'Gästlogin',
        mark3: 'Lätt att dela',
      },
      threeIPhonesArr: {
        iPhone1: {
          img: IPhone12_1Se,
          text: 'Du får notifieringar om det du är intresserad av och vill veta mer om, i realtid.',
        },
        iPhone2: {
          img: IPhone12_2Se,
          text: 'Alla evenemang visas i din stad, presenterad på ett pedagosiskt och vackert sätt.',
        },
        iPhone3: {
          img: IPhone12_3Se,
          text: 'När det är viktigt med information, får du alarm baserad på ditt område.',
        },
      },
      playYourPartArr: {
        item1: {
          title: 'Rapportera fel',
          text: 'Om det finns skador på allmänna platser som stör, rapportera dem snabbt.',
        },
        item2: {
          title: 'Föreslå idéer',
          text: 'Att vara en del av positiv förändring i din stad känns bra, föreslå idéer.',
        },
        item3: {
          title: 'Uppmärksamma klagomål',
          text: 'Du kanske inte vill vänta och ringa på telefonen för att klaga? Gör det här, det tar en minut.',
        },
        item4: {
          title: 'Ställ frågor',
          text: 'Ställ frågor enkelt och snabbt direkt i appen.',
        },
        IPhone12_4: IPhone12_4Se,
        IPhone12_5: IPhone12_5Se,
      },
      'Everyday Life': 'Ditt vardagsliv blir mycket enklare med Citiapp',
      h1: 'När du känner dig i kontroll',
      'Play part': 'Ta kontroll över din del i samhället',
      Calendar: 'Kalender',
      'With Calendar':
        'Med kalendern kan du se alla evenemang som händer i din stad. Inte bara det, du kan spara favoritevenemang och få notifieringar när det är dags.',
      Group7955: Group7955Se,
      Group7957: Group7957Se,
      Start22Screen1: Start22Screen1Se,
      Start22Screen2: Start22Screen2Se,
      'My Pages': 'Mina Sidor',
      'direct communication':
        'Mina sidor är verktyget för direktkontakt för dig, när det handlar om frågor eller rapporter. Du kan få uppdateringar om status, vad som händer och när ditt problem blir löst.',
    },
    DashboardSection: {
      'right information': 'När rätt information delas i realtid',
      h1: 'Appen tar hand om allt',
      greenMarksArr: {
        mark1: 'Förvandla data till insikter',
        mark2: 'Hantera ditt nätverk',
        mark3: '24/7 support',
      },
      fourNotes: {
        note1:
          'Få en snabb överblick över skadeärenden, förslag, klagomål och frågor med bara ett klick.',
        note2:
          'Lägg till, redigera och ta bort nyheter, alarm och evenemang och skicka dem till alla invånare snabbt och enkelt.',
        note3:
          'Automatisera en del av dina arbetsteg så du kan få rapporter som är effektiva och blir löst snabbt.',
        note4:
          'Ta vara på fördelarna av att invånare är delaktig och engagerad i att utveckla och stärka din stad.',
      },
      'Get insights': 'Lär känna invånarnas intressen',
      checkboxes: {
        check1: `Ta emot nya rapporteringar och skicka dem vidare till experter, eller automatisera det.`,
        check2: `Vi kan prata mer om de olika funktionerna när vi träffas.`,
      },
      'Filter reports': 'Du kan filtrera ärenden enligt dina önskemål',
      'Personalise dashboard':
        'Gör din kontrollpanel personlig, precis på det sätt du tycker om och som är mest effektiv för dig.',
      Reporters: 'Rapporterare',
      'Any citizen':
        'Alla invånare i din stad är rapporterare, men du kan också lägga till specifika eller interna rapporterare. Till exempel myndighetskontor, skolor etc. Låt oss prata mer när vi träffas.',
      Suppliers: 'Entreprenader',
      'Service providers':
        'Entreprenader kan också använda din kontrollpanel och ändra status på rapporter, för att du ska vara uppdaterad. Vill de inte använda det? De kan också få ärenden via e-post.',
    },
    AboutSection: {
      'Making citizens': 'Gör vardagen lättare för invånare',
      'In our':
        'Information har blivit mer lättillgängligt och att dela den har aldrig varit enklare. Ändå, har vi inte sett potentialen av teknologi som bygger smartare städer och gör livet lättare för invånarna. När andra pratar om smarta städer, pratar vi om glada invånare.',
      'Olivux is':
        'Olivux är ett specialiserat IT-företag i Sundsvall, Sverige, som fokuserar på hållbara och säkra lösningar som hjälper till att bygga smarta städer. Tack vare AI, IoT och Cloud Computing teknologier som tillåter oss att automatiskt lära från användarens beteende, behov och intressen. Det gör att vi kan skapa lösningar med hög prestanda och kvalitet. Citiapp är vår första app som gör invånarna gladare och ärenden för myndigheter mer tillgänlig, hanterbar och informativ.',
      'We Are': 'Vi är Olivux',
      'My name':
        'Mitt namn är Samir Shnino, en utvecklare och lösningsartitekt med bred erfarenhet av att utveckla lösningar och IT system för den privata och den offentliga sektorn, som är innovativ, intuitiv och användarfokuserad. Idén om Citiapp började ta form efter olika möten med människor runt om mig, de försökte att kontrollera detaljer kring deras vardagsliv, få tillgång till information direkt och reducera oljud från att använda olika tjänster som inte har någon koppling. Jag insåg att jag kan göra bättre och skapa en lösning som tar bort roten till problemen. Jag bestämde mig för att sluta mitt 9 till 5 jobb som anställd och startade mitt företag för att följa min passion och översätta mina idéer till en användbar och effektiv lösning, Citiapp!',
      'Given my':
        'Med tanke på min erfarenhet från att arbeta i både den privata och den offentliga IT sektorn, tillsammans med mitt teams imponerande talanger och kvalifikationer, utvecklade vi en klar vision över vad vi ville göra. Vi har självförtroende och vi vet vad som krävs för att bygga ett system med de rätta teknologier som gör invånare lyckligare. Vår expertis inom UX design hjälper oss att förstå hur viktig användarvänligheten och vad värdet bör vara i vår app. Vi dedikerar tid med att designa och testa våra appar för att de ska överstiga förväntningarna av våra användare.',
      'Our values': 'Värderingar',
      checkboxesArr: {
        check1: {
          title: 'Kultur för enkelhet',
          text: 'Vi tänker enkelhet, och designar för att maximera användarvänligheten för hållbarhet och värde i våra appar.',
        },
        check2: {
          title: 'Kompromissa aldrig kvalitet',
          text: 'Kvalitet är nyckeln för oss.',
        },
        check3: {
          title: 'Less is more',
          text: 'Vi siktar på att ersätta många av våra användares appar med bara ett fåtal hjälpsamma och värdefullar appar.',
        },
        check4: {
          title: 'Var transparent och pålitlig',
          text: 'Vi är transparenta med våra kunder och vårt team.',
        },
      },
      'Our mission': 'Uppdrag',
      'We are':
        'We are on a mission to building new cutting-edge solutions that will make life easier, the community more exciting, and information more personalised, on-time, and accessible for citizens. We are building Citiapp, the “Citizen Super App”.',
      'Our vision': 'Vision',
      'Setting the':
        'Fastställer riktmärket för att utnyttja den fulla potentialen av nuvarande teknik för ett premium medborgarliv.',
      'Leadership team': 'Ledning',
      'Founder CEO':
        'Grundare, CEO and lösningsarkitekt på Olivux. 15 års erfarenhet av att designa och utveckla IT-system inom den offentliga sektorn. Heltidsentusiast kring allt som rör smarta städer.',
      'CIO and innovation manager':
        'CIO och innovationsmanager på Olivux. 15 års erfarenhet i utveckling till den offentliga sektorn. Certifierad förändringsmanager CIO.',
      'Senior Advisor':
        'Senior rådgivare, 25 års erfarenhet som verkställande direktör och som medlem i verkställande styrelser, i stor del inom IT riktat internationellt.',
      'Sales Manager':
        'Försäljningschef och affärsutvecklare med över 30 års erfarenhet inom IT-tjänsteleverans, inklusive roller som VD och försäljningschef, både på nationell och internationell nivå.',
    },
    RequestSection: {
      'Send your':
        'Skicka en förfrågan nu och en av våra säljare kommer att kontakta dig inom kort.',
      'Full Name': 'Namn',
      'Work Email': 'E-post arbete',
      'Phone Number': 'Telefonnummer',
      City: 'Stad',
      'Your Message': 'Ditt meddelande',
      'Submit Request': 'Skicka förfrågan',
      'By Submitting': {
        text: 'By Submitting you are accepting the ',
        link: 'terms of services and privacy policy.',
      },
    },
    FaqSection: {
      h4: 'Frågor och svar',
      faq1: {
        title: 'Säkerhet och behörigheter',
        text: 'Lär dig mer om sekretess och behörigheter som associeras med Citiapp och alla dess applikationer.',
        info1: {
          title: 'Sparar Citiapp någon personlig data?',
          text: 'Personlig data är designad och byggd för att tänka på principerna och delge säkerheter för att skydda data, till exempel komplett anonymitet. Vi sparar en del av din data som exempelvis namn, telefonnummer och e-post, men vi säkerställer att den datan är säker och inget tredje-part nätverk kommer att kunna få åtkomst. Vi följer med andra ord GDPR.',
        },
        info2: {
          title:
            'Mäter Citiappen och delar enhetens platsdata eller e-post med en tredje-parts annonserings nätverk?',
          text: 'Invånare kommer att bli tillfrågad av Citiapp om att ge tillåtelse att spåra deras plats. Appen spårar invånarens plats för att ge bästa information i realtid. Inget tredje-partsnätverk kommer åt den här spårningsinformationen, och den kommer endast att användas av Citiapp. Invånare kan alltid stänga av spårningsplatsen från appinställningar.',
        },
        info3: {
          title: 'Vilka metoder finns det för att logga in på Citiapp?',
          text: 'Logga in med BankID eller Guest är de två främsta sätten som invånare loggar in på Citiapp mobil. Ändå har vi två valfria metoder, nämligen Apple Login och Google Login, men de måste väljas av kommunen om de vill ha dem i sin app eller inte. För kommunanvändare att logga in på Citiapp Dashboard, används inloggning via e-post och lösenord, men vi har en plan för att implementera SAML 2.0 inom en närmare framtid för loggning av kommunanvändare.',
        },
      },
      faq2: {
        title: 'Priser och abonnemang',
        text: 'Frågor om pris och hur mycket det kostar att använda ekosystemet som är Citiapp.',
        info1: {
          title: 'Hur mycket betalar vi som kommun/stad?',
          text: 'Det spelar roll vilka funktioner din kommun väljer att ha i er app. Kom ihåg att många funktioner är valbara. Du kan alltid välja att lägga till och ta bort dem. Så du betalar bara för de funktioner som du använder. Antalet potentiella användare som använder appen, har också betydelse för priset du betalar.',
        },
        info2: {
          title: 'Är underhåll och support inkluderat i priset?',
          text: 'Vi tror att underhåll och support är delar av alla produkter. Därför ingår underhåll och support alltid i priset för våra appar.',
        },
      },
      faq3: {
        title: 'Citiapp och funktioner',
        text: 'Ta en närmare tidd på hur Citiapp fungerar, organiserar, och stöder staden som du befinner dig i.',
        info1: {
          title: 'Vad är Citiapp?',
          text: 'Citiapp “Citizen Super Application” är namnet på ekosystemet som inkluderar få mobil och webb appar. Citiapp är en mobil applikation som används av invånare. Andra Citiapp webbplatser kommer att användas av kommuner och andra intressenter för att ge invånarna information.',
        },
        info2: {
          title: 'Hur många funktioner har Citiapp?',
          text: 'Citiapp är en applikation med många funktioner. Dessa funktioner är grupperade i sex kategorier för att underlätta för invånare att använda Citiapp och förenkla användarupplevelsen.',
        },
        info3: {
          title: 'Behöver kunderna ha alla dessa funktioner?',
          text: 'Även om det finns viktiga funktioner för alla kommuner som standard, kan kunderna välja från många andra valfria delar att inkludera i sin Citiapp.',
        },
        info4: {
          title:
            'Kan en kund välja en ny funktion som du inte har i dina funktionskategorier?',
          text: 'Olivux är invånarcentrerat företag som strävar efter att göra kunderna nöjda och möta deras krav. Därför kan anpassade funktioner läggas till mot en extra kostnad baserat på omfattning och komplexitet.',
        },
      },
    },
  },
};
