import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Dots from './Dots';
import Checkbox3 from './Checkbox3';
import Rectangle2099 from '../assets/images/Rectangle2099.png';
import Rectangle2101 from '../assets/images/Rectangle2101.png';
import Dsc036261 from '../assets/images/DSC036261.png';
import Icon16 from '../assets/images/16.svg';
import Icon24 from '../assets/images/24.svg';
import Icon41 from '../assets/images/41.svg';
import Team1 from '../assets/images/team1.png';
import Team2 from '../assets/images/Team 4.png';
import Team3 from '../assets/images/team3.png';
import Team4 from '../assets/images/team2.png';
import './AboutSection.scss';
import ThreeGreenLines from './ThreeGreenLines';
import TeamCard from './TeamCard';

export default function AboutSection() {
  const { t } = useTranslation();

  const checkboxesArr = [
    {
      title: t('AboutSection.checkboxesArr.check1.title'),
      text: t('AboutSection.checkboxesArr.check1.text'),
    },
    {
      title: t('AboutSection.checkboxesArr.check2.title'),
      text: t('AboutSection.checkboxesArr.check2.text'),
    },
    {
      title: t('AboutSection.checkboxesArr.check3.title'),
      text: t('AboutSection.checkboxesArr.check3.text'),
    },
    {
      title: t('AboutSection.checkboxesArr.check4.title'),
      text: t('AboutSection.checkboxesArr.check4.text'),
    },
  ];

  return (
    <div className='about-section'>
      <Container>
        <Row className='making-life-easier'>
          <Col xs={12} lg={6} className='text-col'>
            <h2 className='position-relative'>
              {t('AboutSection.Making citizens')}
              <Image src={Rectangle2101} className='rectangle2101' />
              <ThreeGreenLines />
            </h2>
            <Image
              src={Rectangle2099}
              className='rectangle2099 d-block d-lg-none'
            />
            <p className='footer-font'>
              {t('AboutSection.In our')}
              <br />
              <br />
              {t('AboutSection.Olivux is')}
            </p>
          </Col>
          <Col xs={0} lg={6} className='d-flex align-items-center'>
            <Image
              src={Rectangle2099}
              className='rectangle2099 d-none d-lg-block'
            />
          </Col>
        </Row>
      </Container>
      <div className='pink-rectangle'>
        <Container>
          <Row className='we-are-olivux-row'>
            <Col
              xs={{ span: 12, order: 2 }}
              lg={{ span: 6, order: 1 }}
              className='d-flex align-items-center'
            >
              <Image src={Dsc036261} className='dsc036261 d-none d-lg-block' />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              lg={{ span: 6, order: 2 }}
              className='text-col position-relative'
            >
              <h2>{t('AboutSection.We Are')}</h2>
              <Image src={Dsc036261} className='dsc036261 d-block d-lg-none' />
              <p className='footer-font'>
                {t('AboutSection.My name')}
                <br />
                <br />
                {t('AboutSection.Given my')}
              </p>
              <Dots x={6} y={5} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className='three-thesises-row'>
          <Col xs={12} className='d-flex flex-column align-items-center'>
            <Image src={Icon41} className='icon' />
            <h4>{t('AboutSection.Our values')}</h4>
          </Col>
          {checkboxesArr.map((item, i) => {
            return (
              <Col key={i} xs={12} lg={6}>
                <Checkbox3 title={item.title} text={item.text} />
              </Col>
            );
          })}
        </Row>
        <Row className='vision-mission'>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 6 }}
            className='d-flex flex-column align-items-center col'
          >
            <Image src={Icon24} className='icon' />
          </Col>
          <Col
            xs={{ order: 3, span: 12 }}
            lg={{ order: 2, span: 6 }}
            className='d-flex flex-column align-items-center col'
          >
            <Image src={Icon16} className='icon' />
          </Col>
          <Col
            xs={{ order: 2, span: 12 }}
            lg={{ order: 3, span: 6 }}
            className='d-flex flex-column align-items-center'
          >
            <h4>{t('AboutSection.Our mission')}</h4>
            <p className='footer-font text-center'>
              {t('AboutSection.We are')}
            </p>
          </Col>
          <Col
            xs={{ order: 4, span: 12 }}
            lg={{ order: 4, span: 6 }}
            className='d-flex flex-column align-items-center'
          >
            <h4>{t('AboutSection.Our vision')}</h4>
            <p className='footer-font text-center'>
              {t('AboutSection.Setting the')}
            </p>
          </Col>
        </Row>
      </Container>
      <div className='leadership-team'>
        <Container>
          <Row>
            <Col xs={12}>
              <h2>{t('AboutSection.Leadership team')}</h2>
            </Col>
            <Col xs={12} lg={6}>
              <TeamCard
                avatar={Team1}
                name='Samir Shnino'
                desc={t('AboutSection.Founder CEO')}
                href='https://www.linkedin.com/in/samir-shnino-5821a733'
              />
            </Col>
            <Col xs={12} lg={6} className='right'>
              <TeamCard
                avatar={Team2}
                name='Stefan Ek'
                desc={t('AboutSection.Sales Manager')}
                href='https://www.linkedin.com/in/stefan-ek-2a472b2/'
              />
            </Col>
            <Col xs={12} lg={6}>
              <TeamCard
                avatar={Team3}
                name='Per Lundgren'
                desc={t('AboutSection.Senior Advisor')}
                href='https://www.linkedin.com/in/per-lundgren-aa999941'
              />
            </Col>
            <Col xs={12} lg={6} className='right'>
              <TeamCard
                avatar={Team4}
                name='Linda Gustafsson'
                desc={t('AboutSection.CIO and innovation manager')}
                href='https://www.linkedin.com/in/linda-gustafsson%F0%9F%8C%9F-147a2059'
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
