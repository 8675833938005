import { Container, Row, Col, Button, Image, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GreenMark from './GreenMark';
import ClearSymbol from '../assets/images/ClearSymbol.svg';
import DesktopDownload from '../assets/images/DesktopDownload.svg';
import List from '../assets/images/List.svg';
import Hint from '../assets/images/Hint.svg';
import Rectangle2100 from '../assets/images/Rectangle2100.png';
import Calendar from '../assets/images/calendar.svg';
import EventManagement2 from '../assets/images/event-management2.svg';
import Calendar1 from '../assets/images/Calendar1.png';
import './MobileAppSection.scss';

export default function MobileAppSection() {
  const { t } = useTranslation();

  const greenMarksArr = [
    t('MobileAppSection.greenMarksArr.mark1'),
    t('MobileAppSection.greenMarksArr.mark2'),
    t('MobileAppSection.greenMarksArr.mark3'),
  ];

  const threeIPhonesArr = [
    {
      img: t('MobileAppSection.threeIPhonesArr.iPhone1.img'),
      title: '1',
      text: t('MobileAppSection.threeIPhonesArr.iPhone1.text'),
    },
    {
      img: t('MobileAppSection.threeIPhonesArr.iPhone2.img'),
      title: '2',
      text: t('MobileAppSection.threeIPhonesArr.iPhone2.text'),
    },
    {
      img: t('MobileAppSection.threeIPhonesArr.iPhone3.img'),
      title: '3',
      text: t('MobileAppSection.threeIPhonesArr.iPhone3.text'),
    },
  ];

  const playYourPartArr = [
    {
      img: ClearSymbol,
      title: t('MobileAppSection.playYourPartArr.item1.title'),
      text: t('MobileAppSection.playYourPartArr.item1.text'),
    },
    {
      img: DesktopDownload,
      title: t('MobileAppSection.playYourPartArr.item2.title'),
      text: t('MobileAppSection.playYourPartArr.item2.text'),
    },
    {
      img: List,
      title: t('MobileAppSection.playYourPartArr.item3.title'),
      text: t('MobileAppSection.playYourPartArr.item3.text'),
    },
    {
      img: Hint,
      title: t('MobileAppSection.playYourPartArr.item4.title'),
      text: t('MobileAppSection.playYourPartArr.item4.text'),
    },
  ];

  return (
    <div className='mobile-app-section'>
      <div className='pink-rectangle'></div>
      <Container>
        <Row className='text-center first-row'>
          <Col>
            <p className='small-poppins'>
              {t('MobileAppSection.Everyday Life')}
            </p>
            <h1>{t('MobileAppSection.h1')}</h1>
          </Col>
        </Row>
        <Row className='justify-content-center green-marks-row'>
          {greenMarksArr.map((item, i) => {
            return (
              <Col xs={6} lg={4} xl={3} key={i}>
                <GreenMark title={item} />
              </Col>
            );
          })}
        </Row>
        <Row className='request-demo-button'>
          <Col className='d-flex justify-content-center'>
            <Button as={Link} to='/request'>
              {t('Header.Request Demo')}
            </Button>
          </Col>
        </Row>
        <Row className='three-iphones text-center'>
          {threeIPhonesArr.map((item, i) => {
            return (
              <Col
                className='d-none d-lg-flex flex-column align-items-center'
                key={i}
              >
                <Image src={item.img} />
                <div className='circle-num rounded-circle d-flex align-items-center justify-content-center'>
                  <h3>{item.title}</h3>
                </div>
                <p className='gilroy-reg-19'>{item.text}</p>
              </Col>
            );
          })}
          <Carousel className='d-lg-none'>
            {threeIPhonesArr.map((item, i) => {
              return (
                <Carousel.Item key={i}>
                  <Image src={item.img} />
                  <div className='circle-num rounded-circle d-flex align-items-center justify-content-center'>
                    <h3>{item.title}</h3>
                  </div>
                  <p className='gilroy-reg-24'>{item.text}</p>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Row>
        <Row className='play-your-part-row'>
          <Col className='position-relative' xs={0} lg={5}>
            <Image
              src={t('MobileAppSection.playYourPartArr.IPhone12_5')}
              className='iphone12-1-img d-none d-lg-block'
            />
            <Image
              src={t('MobileAppSection.playYourPartArr.IPhone12_4')}
              className='iphone12-4-img d-none d-lg-block'
            />
          </Col>
          <Col xs={0} lg={1}></Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={4} lg={0}></Col>
              <Col xs={8} lg={12} className='position-relative'>
                <h2>{t('MobileAppSection.Play part')}</h2>
                <Image src={Rectangle2100} className='rectangle2100' />
              </Col>
              {playYourPartArr.map((item, i) => {
                return (
                  <Col xs={6} className='one-of-four-block' key={i}>
                    <Image src={item.img} className='icon' />
                    <h4>{item.title}</h4>
                    <p className='gilroy-reg-19'>{item.text}</p>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row className='calendar-row'>
          <Col xs={12} lg={4} className='text-center text-lg-start'>
            <Image src={Calendar} className='calendar' />
            <h2>{t('MobileAppSection.Calendar')}</h2>
            <p className='gilroy-reg-24'>
              {t('MobileAppSection.With Calendar')}
            </p>
          </Col>
          <Col xs={12} lg={8} className='d-none d-lg-block'>
            <Image
              src={t('MobileAppSection.Group7955')}
              className='group7955'
            />
          </Col>
          <Carousel className='d-lg-none text-center'>
            <Carousel.Item>
              <Image src={Calendar1} className='img1' />
            </Carousel.Item>
            <Carousel.Item>
              <Image
                src={t('MobileAppSection.threeIPhonesArr.iPhone2.img')}
                className='img3'
              />
            </Carousel.Item>
          </Carousel>
        </Row>
        <Row className='my-pages-row'>
          <Col xs={{ order: 2, span: 12 }} lg={{ order: 1, span: 8 }}>
            <Image
              src={t('MobileAppSection.Group7957')}
              className='group7957 d-none d-lg-block'
            />
            <Carousel className='d-lg-none text-center'>
              <Carousel.Item>
                <Image src={t('MobileAppSection.Start22Screen1')} />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={t('MobileAppSection.Start22Screen2')} />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 4 }}
            className='text-center text-lg-start'
          >
            <Image src={EventManagement2} className='event-management2' />
            <h2>{t('MobileAppSection.My Pages')}</h2>
            <p className='gilroy-reg-24'>
              {t('MobileAppSection.direct communication')}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
