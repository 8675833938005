import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import './MoreThanAppSection.scss';
import SpeechBubble from '../assets/images/speech-bubble.svg';
import MedicalReport from '../assets/images/medical-report.svg';
import Share2 from '../assets/images/share2.svg';
import Hammer from '../assets/images/hammer.svg';
import Compass from '../assets/images/compass.svg';
import RacingFlag from '../assets/images/racing-flag.svg';
import ToyBlock from '../assets/images/toy-block.svg';
import Tracker from '../assets/images/tracker.svg';
import WebDesign from '../assets/images/web-design.svg';
import Rectangle2298 from '../assets/images/Rectangle2298.png';
import Rectangle2299 from '../assets/images/Rectangle2299.png';

export default function MoreThanAppSection() {
  const { t } = useTranslation();

  const moreThanAppElements = [
    {
      img: SpeechBubble,
      title: t('MoreThanAppSection.el0.title'),
      text: t('MoreThanAppSection.el0.text'),
    },
    {
      img: MedicalReport,
      title: t('MoreThanAppSection.el1.title'),
      text: t('MoreThanAppSection.el1.text'),
    },
    {
      img: Share2,
      title: t('MoreThanAppSection.el2.title'),
      text: t('MoreThanAppSection.el2.text'),
    },
    {
      img: Hammer,
      title: t('MoreThanAppSection.el3.title'),
      text: t('MoreThanAppSection.el3.text'),
    },
    {
      img: ToyBlock,
      title: t('MoreThanAppSection.el4.title'),
      text: t('MoreThanAppSection.el4.text'),
    },
    {
      img: WebDesign,
      title: t('MoreThanAppSection.el5.title'),
      text: t('MoreThanAppSection.el5.text'),
    },
    {
      img: Compass,
      title: t('MoreThanAppSection.el6.title'),
      text: t('MoreThanAppSection.el6.text'),
    },
    {
      img: Tracker,
      title: t('MoreThanAppSection.el7.title'),
      text: t('MoreThanAppSection.el7.text'),
    },
    {
      img: RacingFlag,
      title: t('MoreThanAppSection.el8.title'),
      text: t('MoreThanAppSection.el8.text'),
    },
  ];

  return (
    <div className='more-than-app-section'>
      <Container>
        <Row>
          <Col className='text-center header-col'>
            <h3>
              {t('MoreThanAppSection.h2.part1')}
              <span>
                {t('MoreThanAppSection.h2.part2')}
                <Image src={Rectangle2298} className='birthmark' />
              </span>
              {t('MoreThanAppSection.h2.part3')}
            </h3>
          </Col>
        </Row>
        <Row className='elements justify-content-center' xs={2} lg={3}>
          {moreThanAppElements.map((el, i) => {
            return (
              <Col
                key={i}
                className='d-flex flex-column align-items-center element'
              >
                <Image src={el.img} />
                <h5>{el.title}</h5>
                <p className='text-center gilroy-reg-24'>{el.text}</p>
              </Col>
            );
          })}
        </Row>
        <Row className='not-everything-row'>
          <Col>
            <div className='d-none d-lg-flex w-100 h-100 flex-column align-items-center element'>
              <h3>
                {t('MoreThanAppSection.h3.part1')}
                <span>
                  {t('MoreThanAppSection.h3.part2')}
                  <Image src={Rectangle2299} className='birthmark' />
                </span>
                {t('MoreThanAppSection.h3.part3')}
              </h3>
              <p className='text-center gilroy-reg-24'>
                {t('MoreThanAppSection.We have')}
                <br />
                {t('MoreThanAppSection.Want to')}
              </p>
              <Button as={HashLink} to='/request' elementId='faq-section'>
                {t('MoreThanAppSection.Ask Us')}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
