import { Container, Row, Col, Image } from 'react-bootstrap';
import ModTechTabs from './ModTechTabs';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/images/Logo.svg';
import './ModTechSection.scss';

export default function ModTechSection() {
  const { t } = useTranslation();

  return (
    <div className='mod-tech-section'>
      <Container>
        <Row className='text-center top-row'>
          <Col>
            <Image src={Logo} fluid={true} className='d-none d-lg-inline' />
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <h2 className='d-inline-block'>
              {t('ModTechSection.Modern technologies')}
            </h2>
          </Col>
        </Row>
        <ModTechTabs />
      </Container>
    </div>
  );
}
