import Home from '../pages/Home';
import MobileApp from '../pages/MobileApp';
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import Login from '../pages/Login';
import Reset from '../pages/Reset';
import RequestDemo from '../pages/RequestDemo';
import Subscribe from '../pages/Subscribe';
import Privacy from '../pages/Privacy';

import NotFoundPage from '../pages/NotFoundPage';

const appRoutes = [
  {
    name: 'Home',
    component: Home,
    path: '/',
    exact: true,
  },
  {
    name: 'Mobile App',
    component: MobileApp,
    path: '/mobileapp',
    exact: true,
  },
  {
    name: 'Dashboard',
    component: Dashboard,
    path: '/dashboard',
    exact: true,
  },
  {
    name: 'About',
    component: About,
    path: '/about',
    exact: true,
  },
  {
    name: 'Login',
    component: Login,
    path: '/login',
    exact: true,
  },
  {
    name: 'Reset',
    component: Reset,
    path: '/reset',
    exact: true,
  },
  {
    name: 'Request a demo',
    component: RequestDemo,
    path: '/request',
    exact: true,
  },
  {
    name: 'Subscribe',
    component: Subscribe,
    path: '/subscribe',
    exact: true,
  },
  {
    name: 'Privacy',
    component: Privacy,
    path: '/privacy',
    exact: true,
  },
  {
    name: 'Not Found',
    component: NotFoundPage,
    path: '*',
  },
];

export default appRoutes;
