import { Row, Col, Container, Image, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/Logo.svg';
import './LoginInner.scss';

export default function LoginInner() {
  return (
    <Container className='login-inner'>
      <Row>
        <Col>
          <Link to='/'>
            <Image src={Logo} className='logo' />
          </Link>
          <h2>Login</h2>
          <Form>
            <Form.Group>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type='email' placeholder='e.g john@john.com' />
            </Form.Group>
            <Form.Group>
              <Form.Label className='pwd-label'>
                Password
                <Link to='/reset'>Forget Password?</Link>
              </Form.Label>
              <Form.Control type='password' placeholder='Password' />
            </Form.Group>
            <Button type='submit'>Login</Button>
          </Form>
          <p className='small-poppins'>
            This access is only for Citiapp customers. If you can’t access this
            and you think you should, please do contact us at support@citiapp.se
          </p>
        </Col>
      </Row>
    </Container>
  );
}
