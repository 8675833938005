import { Image } from 'react-bootstrap';
import Rectangle2102 from '../assets/images/Rectangle2102.png';
import './ThreeGreenLines.scss';

export default function ThreeGreenLines({ className }) {
  return (
    <div className={'three-green-lines ' + className ?? ''}>
      <Image src={Rectangle2102} className='first' />
      <Image src={Rectangle2102} className='second' />
      <Image src={Rectangle2102} className='third' />
    </div>
  );
}
