import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ThreeGreenLines from './ThreeGreenLines';
import './PrivacySection.scss';
import { Link } from 'react-router-dom';

export default function PrivacySection() {
  const { t } = useTranslation();

  return (
    <Container className='privacy-section'>
      <h1>{t('Privacy.h1')}</h1>
      <ThreeGreenLines />
      <p className='updated'>{t('Privacy.updated')}</p>
      <h5>{t('Privacy.Welcome.h')}</h5>
      <p>{t('Privacy.Welcome.text0')}</p>
      <br />
      <p>{t('Privacy.Welcome.text1')}</p>
      <p>{t('Privacy.Welcome.text2')}</p>
      <p>{t('Privacy.Welcome.text3')}</p>
      <h5>{t('Privacy.Collect.h')}</h5>
      <p>
        {t('Privacy.Collect.text1')}
        <br />
        <br />
      </p>
      <ul>
        <li>{t('Privacy.Collect.li1')}</li>
        <li>{t('Privacy.Collect.li2')}</li>
        <li>{t('Privacy.Collect.li3')}</li>
        <li>{t('Privacy.Collect.li4')}</li>
        <li>{t('Privacy.Collect.li5')}</li>
        <li>{t('Privacy.Collect.li6')}</li>
        <li>{t('Privacy.Collect.li7')}</li>
        <li>{t('Privacy.Collect.li8')}</li>
        <li>{t('Privacy.Collect.li9')}</li>
      </ul>
      <p>{t('Privacy.Collect.text2')}</p>
      <p>{t('Privacy.Collect.text3')}</p>
      <p>{t('Privacy.Collect.text4')}</p>
      <p>{t('Privacy.Collect.text5')}</p>

      <h5>{t('Privacy.Keep.h')}</h5>
      <p>{t('Privacy.Keep.text')}</p>
      <h5>{t('Privacy.Need.h')}</h5>
      <li className='bold-li'>{t('Privacy.Need.Accessibility.h')}</li>
      <p>{t('Privacy.Need.Accessibility.text')}</p>
      <li className='bold-li'>{t('Privacy.Need.Curated.h')}</li>
      <p>{t('Privacy.Need.Curated.text1')}</p>
      <br />
      <p>{t('Privacy.Need.Curated.text2')}</p>
      <br />
      <p>{t('Privacy.Need.Curated.text3')}</p>
      <li className='bold-li'>{t('Privacy.Need.Personalisation.h')}</li>
      <p>{t('Privacy.Need.Personalisation.text1')}</p>
      <br />
      <p>{t('Privacy.Need.Personalisation.text2')}</p>
      <li className='bold-li'>{t('Privacy.Need.Marketing.h')}</li>
      <p>{t('Privacy.Need.Marketing.text1')}</p>
      <br />
      <p>{t('Privacy.Need.Marketing.text2')}</p>
      <li className='bold-li'>{t('Privacy.Need.Contact.h')}</li>
      <p>{t('Privacy.Need.Contact.text1')}</p>
      <br />
      <p>{t('Privacy.Need.Contact.text2')}</p>
      <h5>{t('Privacy.Retain.h')}</h5>
      <p>{t('Privacy.Retain.text1')}</p>
      <br />
      <p>{t('Privacy.Retain.text2')}</p>
      <h5>{t('Privacy.Disclose.h')}</h5>
      <p>{t('Privacy.Disclose.text')}</p>
      <li className='bold-li'>{t('Privacy.Disclose.Business.h')}</li>
      <p>{t('Privacy.Disclose.Business.text')}</p>
      <li className='bold-li'>{t('Privacy.Disclose.Users.h')}</li>
      <p>{t('Privacy.Disclose.Users.text')}</p>
      <li className='bold-li'>{t('Privacy.Disclose.Legal.h')}</li>
      <p>{t('Privacy.Disclose.Legal.text')}</p>
      <h5>{t('Privacy.Rights.h')}</h5>
      <p>{t('Privacy.Rights.text1')}</p>
      <br />
      <p>{t('Privacy.Rights.text2')}</p>
      <h5>{t('Privacy.AgeAppropriate.h')}</h5>
      <p>{t('Privacy.AgeAppropriate.text')}</p>
      <h5>{t('Privacy.Cookies.h')}</h5>
      <p>{t('Privacy.Cookies.text1')}</p>
      <ul>
        <li>{t('Privacy.Cookies.li1')}</li>
        <li>{t('Privacy.Cookies.li2')}</li>
        <li>{t('Privacy.Cookies.li3')}</li>
        <li>{t('Privacy.Cookies.li4')}</li>
      </ul>
      <p>{t('Privacy.Cookies.text2')}</p>
      <h5>{t('Privacy.Updates.h')}</h5>
      <p>{t('Privacy.Updates.text')}</p>
      <h5>{t('Privacy.Contacting.h')}</h5>
      <p>
        {t('Privacy.Contacting.text')}
        <a href={'mailto:' + t('Privacy.Contacting.link')}>
          {t('Privacy.Contacting.link')}
        </a>
      </p>
    </Container>
  );
}
