import { useState } from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ThreeFaces from './ThreeFaces';
import Dots from './Dots';
import ThreeGreenLines from './ThreeGreenLines';
import Rectangle20992 from '../assets/images/Rectangle20992.png';
import Rectangle2100 from '../assets/images/Rectangle2100.png';
import Rectangle2101 from '../assets/images/Rectangle2101.png';
import './RequestSection.scss';

const requestDemoApiUrl =
  'https://api.olivux.se/Olivux/ContactForm';
const requestDemoToken = 'This Is Email Secret used by user to protect API';

export default function RequestSection() {
  const [requestDemoResponseStatus, setRequestDemoResponseStatus] =
    useState(null);
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      city: '',
      message: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .matches(/^[a-z A-Z\u00C0-\u024F\u1E00-\u1EFF]+$/)
        .required(),
      email: Yup.string().email().required(),
      phone: Yup.string()
        .matches(/^\+?[\d ()-]+$/)
        .required(),
      city: Yup.string()
        .matches(/^[a-z A-Z\u00C0-\u024F\u1E00-\u1EFF]+$/)
        .required(),
      message: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(requestDemoApiUrl, {
          fullname: values.fullName,
          email: values.email,
          phone: values.phone,
          city: values.city,
          message: values.message,
          source: 'Citiapp',
          token: requestDemoToken,
        });

        if (response.status === 200) resetForm();
        setRequestDemoResponseStatus(response.status);
      } catch (error) {
        console.error('Request error:', error.response || error.message);
        setRequestDemoResponseStatus('error');
      }
    },
  });

  const { t } = useTranslation();

  return (
    <div className='request-section'>
      <Container>
        <Row>
          <Col xs={0} lg={7} className='d-flex align-items-center'>
            <div className='images position-relative d-none d-lg-block'>
              <Image src={Rectangle20992} className='rectangle20992' />
              <Dots x={6} y={5} />
              <Image src={Rectangle2100} className='rectangle2100' />
              <ThreeGreenLines />
              <Image src={Rectangle2101} className='rectangle2101' />
            </div>
          </Col>
          <Col xs={12} lg={5} className='form-col position-relative'>
            <h4 className='text-center'>{t('Header.Request Demo')}</h4>
            <p className='footer-font text-center first-notice'>
              {t('RequestSection.Send your')}
            </p>
            <div className='three-faces-box text-center'>
              <ThreeFaces className='d-inline-block' />
            </div>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <Form.Group>
                <Form.Label>{t('RequestSection.Full Name')}</Form.Label>
                <Form.Control
                  name='fullName'
                  type='text'
                  {...formik.getFieldProps('fullName')}
                  isValid={
                    formik.values.fullName &&
                    formik.touched.fullName &&
                    !formik.errors.fullName
                  }
                  isInvalid={
                    formik.values.fullName &&
                    formik.touched.fullName &&
                    formik.errors.fullName
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('RequestSection.Work Email')}</Form.Label>
                <Form.Control
                  name='email'
                  type='email'
                  {...formik.getFieldProps('email')}
                  isValid={
                    formik.values.email &&
                    formik.touched.email &&
                    !formik.errors.email
                  }
                  isInvalid={
                    formik.values.email &&
                    formik.touched.email &&
                    formik.errors.email
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('RequestSection.Phone Number')}</Form.Label>
                <Form.Control
                  name='phone'
                  type='tel'
                  {...formik.getFieldProps('phone')}
                  isValid={
                    formik.values.phone &&
                    formik.touched.phone &&
                    !formik.errors.phone
                  }
                  isInvalid={
                    formik.values.phone &&
                    formik.touched.phone &&
                    formik.errors.phone
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('RequestSection.City')}</Form.Label>
                <Form.Control
                  name='city'
                  type='text'
                  {...formik.getFieldProps('city')}
                  isValid={
                    formik.values.city &&
                    formik.touched.city &&
                    !formik.errors.city
                  }
                  isInvalid={
                    formik.values.city &&
                    formik.touched.city &&
                    formik.errors.city
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('RequestSection.Your Message')}</Form.Label>
                <Form.Control
                  name='message'
                  as='textarea'
                  rows={5}
                  {...formik.getFieldProps('message')}
                  isValid={
                    formik.values.message &&
                    formik.touched.message &&
                    !formik.errors.message
                  }
                  isInvalid={
                    formik.values.message &&
                    formik.touched.message &&
                    formik.errors.message
                  }
                />
              </Form.Group>
              {requestDemoResponseStatus === 200 && (
                <p className='gilroy-semibold-19 text-success text-center'>
                  A demo request was successfully sent
                </p>
              )}
              {requestDemoResponseStatus &&
                requestDemoResponseStatus !== 200 && (
                  <p className='gilroy-semibold-19 text-danger text-center'>
                    {`Sorry, the error has occurred. Please try again.`}
                  </p>
                )}
              <Button
                type='submit'
                disabled={
                  formik.errors.fullName ||
                  formik.errors.email ||
                  formik.errors.phone ||
                  formik.errors.city ||
                  formik.errors.message ||
                  !formik.values.fullName ||
                  !formik.values.email ||
                  !formik.values.phone ||
                  !formik.values.city ||
                  !formik.values.message
                }
              >
                {t('RequestSection.Submit Request')}
              </Button>
            </Form>
            <p className='footer-font text-center last-notice'>
              {t('RequestSection.By Submitting.text')}
              <HashLink to='/privacy#top' className='last-notice-link'>
                {t('RequestSection.By Submitting.link')}
              </HashLink>
            </p>
            <Image src={Rectangle2100} className='rectangle2100 d-lg-none' />
            <Image src={Rectangle2101} className='rectangle2101 d-lg-none' />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
