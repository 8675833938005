import { Container, Row, Col, Button, Image, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GreenMark from './GreenMark';
import Checkbox2 from './Checkbox2';
import AppleMacbook from '../assets/images/AppleMacbookPro13_Silver.png';
import Main11 from '../assets/images/Main11.png';
import Rectangle2100 from '../assets/images/Rectangle2100.png';
import Rectangle2100_2 from '../assets/images/Rectangle2100_2.png';
import Rectangle2684 from '../assets/images/Rectangle2684.png';
import Rectangle2685 from '../assets/images/Rectangle2685.png';
import MedicalReport1 from '../assets/images/medical-report1.svg';
import EventManagement2 from '../assets/images/event-management2.svg';
import './DashboardSection.scss';

export default function DashboardSection() {
  const { t } = useTranslation();

  const greenMarksArr = [
    t('DashboardSection.greenMarksArr.mark1'),
    t('DashboardSection.greenMarksArr.mark2'),
    t('DashboardSection.greenMarksArr.mark3'),
  ];

  const fourNotes = [
    {
      title: '1',
      text: t('DashboardSection.fourNotes.note1'),
    },
    {
      title: '2',
      text: t('DashboardSection.fourNotes.note2'),
    },
    {
      title: '3',
      text: t('DashboardSection.fourNotes.note3'),
    },
    {
      title: '4',
      text: t('DashboardSection.fourNotes.note4'),
    },
  ];

  const checkboxes = [
    t('DashboardSection.checkboxes.check1'),
    t('DashboardSection.checkboxes.check2'),
  ];

  return (
    <div className='dashboard-section'>
      <div className='blue-rectangle'></div>
      <Container>
        <Row className='text-center first-row'>
          <Col>
            <p className='small-poppins'>
              {t('DashboardSection.right information')}
            </p>
            <h1>{t('DashboardSection.h1')}</h1>
          </Col>
        </Row>
        <Row className='justify-content-center green-marks-row'>
          {greenMarksArr.map((item, i) => {
            return (
              <Col xs={6} lg={4} xl={3} key={i}>
                <GreenMark title={item} />
              </Col>
            );
          })}
        </Row>
        <Row className='request-demo-button-row'>
          <Col className='text-center'>
            <Button as={Link} to='/request' className='d-inline-flex'>
              {t('Header.Request Demo')}
            </Button>
          </Col>
        </Row>
        <Row className='mac-row'>
          <Col xs={12} className='text-center'>
            <Image src={AppleMacbook} className='mac-img' />
          </Col>
          {fourNotes.map((item, i) => {
            return (
              <Col
                xs={3}
                className='d-none d-lg-flex flex-column align-items-center text-center'
                key={i}
              >
                <div className='circle-num rounded-circle d-flex align-items-center justify-content-center'>
                  <h3>{item.title}</h3>
                </div>
                <p className='gilroy-reg-19'>{item.text}</p>
              </Col>
            );
          })}
          <Col className='d-block d-lg-none'>
            <Carousel>
              {fourNotes.map((item, i) => {
                return (
                  <Carousel.Item key={i}>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <div className='circle-num rounded-circle d-flex align-items-center justify-content-center'>
                        <h3>{item.title}</h3>
                      </div>
                      <p className='gilroy-reg-24 text-center'>{item.text}</p>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
        </Row>
        <Row className='border-row'>
          <Col></Col>
        </Row>
        <Row className='get-val-row'>
          <Col xs={0} lg={5}></Col>
          <Col xs={12} lg={6} className='position-relative'>
            <h2>{t('DashboardSection.Get insights')}</h2>
            <Image
              src={Rectangle2100}
              className='d-none d-lg-block rectangle2100'
            />
            <Image
              src={Rectangle2100_2}
              className='d-lg-none rectangle2100-2'
            />
          </Col>
          <Col xs={0} lg={1}></Col>
          <Col xs={12} lg={7} className='h-100'>
            <div className='h-100 w-100'>
              <Image src={Main11} className='main11' />
            </div>
          </Col>
          <Col xs={12} lg={4} className='position-relative'>
            <div className='w-100 right-side-block'>
              <h4>{t('DashboardSection.Filter reports')}</h4>
              <p className='gilroy-reg-24'>
                {t('DashboardSection.Personalise dashboard')}
              </p>
              {checkboxes.map((checkbox, i) => (
                <Checkbox2 key={i} content={checkbox} />
              ))}
            </div>
          </Col>
          <Col xs={0} lg={1}></Col>
        </Row>
        <Row className='reporters-row'>
          <Col xs={12} lg={4} className='text-center text-lg-start'>
            <Image src={MedicalReport1} className='medical-report1' />
            <h2>{t('DashboardSection.Reporters')}</h2>
            <p className='gilroy-reg-24'>{t('DashboardSection.Any citizen')}</p>
          </Col>
          <Col xs={12} lg={8}>
            <Image src={Rectangle2684} className='rectangle2684' />
          </Col>
        </Row>
        <Row className='suppliers-row'>
          <Col xs={{ order: 2, span: 12 }} lg={{ order: 1, span: 8 }}>
            <Image src={Rectangle2685} className='rectangle2685' />
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 4 }}
            className='text-center text-lg-start text-col'
          >
            <Image src={EventManagement2} className='event-management2' />
            <h2>{t('DashboardSection.Suppliers')}</h2>
            <p className='gilroy-reg-24'>
              {t('DashboardSection.Service providers')}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
