export function buildRoutes(routes, parentRoute = '') {
  let resRoutes = [];

  routes.forEach((route) => {
    const newRoute = parentRoute + route.path;
    resRoutes.push({
      ...route,
      path: newRoute,
    });
    if (route.routes) resRoutes.push(...buildRoutes(route.routes, newRoute));
  });

  return resRoutes;
}
