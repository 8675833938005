import LoginInner from '../components/LoginInner';
import LoginWindow from '../components/LoginWindow';

export default function Login() {
  return (
    <LoginWindow>
      <LoginInner />
    </LoginWindow>
  );
}
